<template>
    <v-row class="fill-height pa-3">
      <v-col cols="12" md="4" class="fill-height">
        <v-card class="fill-height">
          <v-card-text class="d-flex align-center justify-center">
            <v-text-field
                v-model="search"
                dense
                hide-details
                label="Пошук групи"
                single-line
                placeholder="введіть текст для пошуку"
            />
            <v-btn depressed
                   class="ml-2"
                   color="grey lighten-3"
                   height="30" width="46" min-width="46">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text>
            <v-treeview
                dense
                hoverable
                activatable
                :items="items"
                :open="open"
                :search="search"
                :filter="filter"
                :active.sync="selection"
                return-object
                color="grey darken-3"
                style="cursor: pointer !important;"
            >
              <template v-slot:prepend="{ item }">
                <v-icon :color="item.root ? 'grey darken-2' : 'secondary'">mdi-folder</v-icon>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="fill-height">
        <v-card class="fill-height">
          <v-card-text class="d-flex align-center justify-center">
            <v-text-field
                v-model="search_nomenclature"
                dense
                hide-details
                label="Пошуку номенклатури"
                single-line
                placeholder="введіть текст для пошуку"
            />
            <v-btn depressed
                   class="ml-2"
                   color="grey lighten-3"
                   height="30" width="46" min-width="46">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text>
            <div class="table-header" style="display: flex;">
              <div class="table-col" style="flex: 0 0 60px; padding-left: 5px"></div>
              <div class="table-col" style="flex: 0 0 90px; padding-left: 5px">
                Код
              </div>
              <div class="table-col" style="flex: 1">
                Найменування
              </div>
              <div class="table-col" style="flex: 0 0 90px;">
                Од.вим
              </div>
            </div>
            <div class="table-body">
              <div
                  class="table-row" style="display: flex; cursor: pointer"
                  v-for="(item, idx) in nomenclature_list"
                  :key="idx">
                <div class="table-col" style="flex: 0 0 60px; padding-left: 5px">
                  <v-simple-checkbox
                      :value="selected_row === item.id"
                      :ripple="false"
                      @input="changeSelectRow(item.id)"
                      class="pa-0 ma-0"/>
                </div>
                <div class="table-col" style="flex: 0 0 90px; padding-left: 5px">
                  {{ item.code }}
                </div>
                <div class="table-col" style="flex: 1;">
                  {{ item.name }}
                </div>
                <div class="table-col" style="flex: 0 0 90px; padding-left: 5px">
                  {{ item.unit }}
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'nomenclature_list',
  data: () => ({
    items: [
      {
        id: 0,
        name: 'Усі групи',
        root: true,
        children: [
          {
            id: 1,
            name: 'Товари',
            children: [
              {
                id: 11,
                name: 'Бакалія',
              },
              {
                id: 12,
                name: 'Посуд',
              },
              {
                id: 13,
                name: 'Ваговий товар',
              },
            ],
          },
          {
            id: 2,
            name: 'Послуги',
            children: [
              {
                id: 21,
                name: 'Послуги населенню'
              },
              {
                id: 22,
                name: 'Послуги юридичним особам'
              }
            ]
          },
          {
            id: 3,
            name: 'Основні засоби',
            children: [
              {
                id: 31,
                name: 'Будинки'
              },
              {
                id: 32,
                name: 'Машини'
              },
              {
                id: 33,
                name: 'Обладнання'
              }
            ]
          }
        ]
      },
    ],
    nomenclature_list: [
      {
        id: 1,
        name: 'Товар 1',
        unit: 'шт.'
      },
      {
        id: 2,
        name: 'Товар 2',
        unit: 'шт.'
      },
      {
        id: 3,
        name: 'Товар 3',
        unit: 'шт.'
      },
      {
        id: 4,
        name: 'Товар 4',
        unit: 'шт.'
      }
    ],
    selection: [],
    open: [0],
    search: null,
    search_nomenclature: null,
    selected_row: 0
  }),
  computed: {
    filter () {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
  },
}
</script>

<style scoped lang="scss">
.table-col {
  padding: 3px 3px;
  font-size: 0.82rem;
}
.table-header {
  .table-col {
    padding: 5px 3px;
    background-color: #e3e3e3;
    font-weight: 500;
    font-size: 0.8rem;
  }
}
</style>